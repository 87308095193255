/* eslint-disable @typescript-eslint/member-ordering */
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { Theme } from '@api/models/enums';
import { PlcFlagPickerOption } from '@components/modules/common/flag-picker/flag-picker.component';
import { ThemeService } from '@components/modules/common/theme-switcher/theme.service';
import { FLAG_PICKER_OPTIONS } from '@components/modules/layout/toolbar/toolbar.models';
import { TranslateService } from '@ngx-translate/core';

import { MENU_ENTRIES, MenuEntry } from './playground.models';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styles: [
		'.menu-spacer { flex: 1 1 auto; }',
		'.menu-side-nav { padding-right: 50px; }',
		'.mat-drawer-inner-container { overflow: initial }',
		'.menu-side-nav-content { overflow-y: scroll; height: calc(100vh - 64px); }',
		'.menu-side-nav-content::-webkit-scrollbar { width: 5px }',
		'.menu-side-nav-content::-webkit-scrollbar-track { background: #666 }',
		'.menu-side-nav-content::-webkit-scrollbar-thumb { background: white }',
		'.pp-tree-invisible { display: none; }',
		'.pp-tree ul,.pp-tree li { margin-top:0; margin-bottom:0; list-style-type:none; }',
		'@media (max-width: 768px) { mat-toolbar-row h1 { display: none; } }',
	],
})
export class AppComponent implements OnInit {
	public searchControl = new UntypedFormControl();
	public filteredPlaygrounds: Observable<MenuEntry[]>;

	public treeControl = new NestedTreeControl<any>((node) => node.children);
	public dataSource = new MatTreeNestedDataSource<any>();
	public hasChild = (_: number, node: MenuEntry): boolean =>
		!!node.children && node.children.length > 0;
	public initialTheme: Theme = Theme.Dark;
	public options: PlcFlagPickerOption[];
	public selected: PlcFlagPickerOption;

	public get pageIsDeprecated$(): Observable<boolean> {
		return this.route.queryParamMap.pipe(
			map((queryParams) => queryParams.get('deprecated') === 'true'),
		);
	}

	private _playgrounds: MenuEntry[];

	constructor(
		private themeService: ThemeService,
		private router: Router,
		private route: ActivatedRoute,
		private transService: TranslateService,
	) {}

	ngOnInit() {
		this.setTheme(this.initialTheme);

		this.options = FLAG_PICKER_OPTIONS;
		this.selected = this.options.at(0);

		this.transService.setDefaultLang(this.selected.lang);

		this.dataSource.data = MENU_ENTRIES;

		this._playgrounds = this.flat(MENU_ENTRIES);

		this.filteredPlaygrounds = this.searchControl.valueChanges.pipe(
			startWith(''),
			map((term: string) =>
				term ? this._filter(term) : this._playgrounds.slice(),
			),
		);
	}

	public setTheme(theme: Theme): void {
		this.themeService.setTheme(theme);
	}

	public changeLang(option: PlcFlagPickerOption): void {
		this.transService.use(option.lang);
	}

	public handleActivated(event: MatAutocompleteSelectedEvent): void {
		const {
			option: { value },
		} = event;
		const entry = this._playgrounds.find(({ name }) => name === value);
		this.navigate(entry);
	}

	public navigate({ path, deprecated }: MenuEntry): void {
		this.router.navigate([path], { queryParams: { deprecated } });
	}

	private flat(nodes: MenuEntry[]): MenuEntry[] {
		let playgrounds: MenuEntry[] = [];

		for (const node of nodes) {
			if (node.children) {
				playgrounds = playgrounds.concat(this.flat(node.children));
			} else {
				const { name, path, deprecated } = node;
				playgrounds = playgrounds.concat({ name, path, deprecated });
			}
		}

		return playgrounds;
	}

	private _filter(value: string): MenuEntry[] {
		const filterValue = value.toLowerCase();
		return this._playgrounds.filter(
			(state) => state.name.toLowerCase().indexOf(filterValue) === 0,
		);
	}
}
