import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomePlayground } from './home/home.playground';

const routes: Routes = [
	{
		path: 'components',
		loadChildren: () =>
			import('./components/components.module').then(
				(m) => m.ComponentsModule,
			),
	},
	{
		path: 'styles',
		loadChildren: () =>
			import('./styles/styles.module').then((m) => m.StylesModule),
	},
	{
		path: 'assets',
		loadChildren: () =>
			import('./assets/assets.module').then((m) => m.AssetsModule),
	},
	{
		path: 'home',
		component: HomePlayground,
	},
	{
		path: '**',
		redirectTo: 'home',
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
