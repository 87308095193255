export interface MenuEntry {
	name: string;
	path?: string;
	children?: MenuEntry[];
	deprecated?: boolean;
}

export const MENU_ENTRIES: MenuEntry[] = [
	{
		name: 'Assets',
		children: [
			{
				name: 'Icons',
				path: 'assets/icons',
			},
		],
	},
	{
		name: 'Components',
		children: [
			{
				name: 'Commons',
				children: [
					{ name: 'Avatar', path: 'components/commons/avatar' },
					{ name: 'Button', path: 'components/commons/button' },
					{ name: 'Dialog', path: 'components/commons/dialog' },
					{
						name: 'Disclaimer',
						path: 'components/commons/disclaimer',
					},
					{
						name: 'Flag Picker',
						path: 'components/commons/flag-picker',
					},
					{ name: 'Icon', path: 'components/commons/icon' },
					{
						name: 'Icon Badge',
						path: 'components/commons/icon-badge',
					},
					{ name: 'Label', path: 'components/commons/label' },
					{ name: 'Link', path: 'components/commons/link' },
					{ name: 'Loader', path: 'components/commons/loader' },
					{
						name: 'Progress Bar',
						path: 'components/commons/progress-bar',
					},
					{
						name: 'Raising Panel',
						path: 'components/commons/raising-panel',
					},
					{ name: 'Stepper', path: 'components/commons/stepper' },
					{ name: 'Toast', path: 'components/commons/toast' },
					{
						name: 'Toggle Button Three',
						path: 'components/commons/toggle-button-three',
					},
					{ name: 'Wizard', path: 'components/commons/wizard' },
				],
			},
			{
				name: 'Forms',
				children: [
					{ name: 'Checkbox', path: 'components/forms/checkbox' },
					{
						name: 'Check Panel Group',
						path: 'components/forms/check-panel-group',
					},
					{
						name: 'File Picker',
						path: 'components/forms/file-picker',
					},
					{
						name: 'Number Input',
						path: 'components/forms/number-input',
					},
					{ name: 'Pin Code', path: 'components/forms/pin-code' },
					{
						name: 'Range input',
						path: 'components/forms/range-input',
					},
					{ name: 'Text Input', path: 'components/forms/text-input' },
					{
						name: 'Radio Buttons',
						path: 'components/forms/radio-buttons',
					},
				],
			},
			{
				name: 'Game',
				children: [
					{
						name: 'Alphabet Soup Phase',
						children: [
							{
								name: 'Alphabet Soup',
								path: 'components/game/alphabet-soup/alphabet-soup',
							},
						],
					},
					{
						name: 'Bagel Phase',
						children: [
							{
								name: 'Bagel char',
								path: 'components/game/bagel/bagel-char',
							},
							{
								name: 'Bagel dial',
								path: 'components/game/bagel/bagel-dial',
							},
							{
								name: 'Player bagel',
								path: 'components/game/bagel/player-bagel',
							},
							{
								name: 'Viewer bagel',
								path: 'components/game/bagel/viewer-bagel',
							},
						],
					},
					{
						name: 'Commons',
						children: [
							{
								name: 'Clue timer',
								path: 'components/game/commons/clue-timer',
							},
							{
								name: 'Game row',
								path: 'components/game/commons/game-row',
							},
							{
								name: 'Phase Score Counter',
								path: 'components/game/commons/phase-score-counter',
							},
							{
								name: 'Players Overview',
								path: 'components/game/commons/players-overview',
							},
							{
								name: 'Role card',
								path: 'components/game/commons/role-card',
							},
							{
								name: 'Score counter',
								path: 'components/game/commons/score-counter',
							},
							{
								name: 'Toggle button',
								path: 'components/game/commons/toggle-button',
								deprecated: true,
							},
							{
								name: 'Toggle button two',
								path: 'components/game/commons/toggle-button-two',
								deprecated: true,
							},
							{
								name: 'Webcam',
								path: 'components/game/commons/webcam',
							},
						],
					},
					{
						name: 'Memory Phase',
						children: [
							{
								name: 'Flipper cell',
								path: 'components/game/memory-phase/flipper-cell',
							},
							{
								name: 'Flipper grid',
								path: 'components/game/memory-phase/flipper-grid',
							},
						],
					},
				],
			},
			{
				name: 'Layout',
				children: [
					{
						name: 'Action Buttons Wrapper',
						path: 'components/layout/action-buttons-wrapper',
						deprecated: true,
					},
					{
						name: 'Background Screen',
						path: 'components/layout/background-screen',
					},
					{
						name: 'Box',
						path: 'components/layout/box',
					},
					{ name: 'Footer', path: 'components/layout/footer' },
					{
						name: 'Panel',
						path: 'components/layout/panel',
						deprecated: true,
					},
					{ name: 'Section', path: 'components/layout/section' },
					{ name: 'Tabs', path: 'components/layout/tabs' },
					{
						name: 'Toolbar Desktop',
						path: 'components/layout/toolbar-desktop',
					},
					{
						name: 'Toolbar Mobile',
						path: 'components/layout/toolbar-mobile',
					},
				],
			},
			{
				name: 'Profile',
				children: [
					{
						name: 'Donut chart',
						path: 'components/profile/donut-chart',
					},
					{
						name: 'User Game Result',
						path: 'components/profile/user-game-result',
					},
					{
						name: 'Image Cropper',
						path: 'components/profile/image-cropper',
					},
				],
			},
		],
	},
	{
		name: 'Styles',
		children: [
			{
				name: 'Animations',
				path: 'styles/animations',
			},
		],
	},
];
