<mat-toolbar color="primary">
	<mat-toolbar-row>
		<button mat-icon-button (click)="sidenav.toggle()">
			<mat-icon aria-hidden="false"> menu </mat-icon>
		</button>

		<h1 style="margin: 0">Pastalacabra</h1>

		<mat-form-field
			style="margin: 0 30px; width: 100%; position: relative; top: 10px"
		>
			<input
				type="text"
				placeholder="Search"
				matInput
				[formControl]="searchControl"
				[matAutocomplete]="auto"
			/>
			<mat-autocomplete
				#auto="matAutocomplete"
				(optionSelected)="handleActivated($event)"
			>
				<mat-option
					*ngFor="let playground of filteredPlaygrounds | async"
					[value]="playground.name"
					(click)="navigate(playground)"
				>
					<mat-icon aria-hidden="false" *ngIf="playground.deprecated">
						report
					</mat-icon>
					<strong style="width: 200px; display: inline-block">
						{{ playground.name }}
					</strong>
					<i> {{ playground.path | beautifyPath }} </i>
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>

		<plc-flag-picker
			style="z-index: 2; margin-right: 12px"
			[options]="options"
			[selected]="selected"
			[dir]="'row'"
			(change)="changeLang($event)"
		/>

		<plc-theme-switcher2
			[initialTheme]="initialTheme"
			(themeChange)="setTheme($event)"
		/>
	</mat-toolbar-row>
	<mat-toolbar-row
		style="background: #c8ab55; justify-content: center"
		*ngIf="pageIsDeprecated$ | async"
	>
		<mat-icon aria-hidden="false">report</mat-icon>
		<mat-icon aria-hidden="false">report</mat-icon>

		<span style="margin: 0 20px">
			This component is <strong>deprecated</strong>. Check the browser
			<i>Console tab</i> for more details
		</span>

		<mat-icon aria-hidden="false">report</mat-icon>
		<mat-icon aria-hidden="false">report</mat-icon>
	</mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container>
	<mat-sidenav #sidenav class="menu-side-nav">
		<mat-tree
			[dataSource]="dataSource"
			[treeControl]="treeControl"
			class="pp-tree"
		>
			<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
				<li class="mat-tree-node">
					<button
						mat-icon-button
						disabled
						*ngIf="node.children"
					></button>
					<span
						*ngIf="node.path"
						(click)="sidenav.toggle() && navigate(node)"
						style="cursor: pointer"
					>
						{{ node.name }}
					</span>
					<mat-icon
						aria-hidden="false"
						style="margin-left: 20px; color: #c8ab55"
						*ngIf="node.deprecated"
					>
						report
					</mat-icon>
					<span *ngIf="!node.path"> {{ node.name }} </span>
				</li>
			</mat-tree-node>
			<mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
				<li>
					<div class="mat-tree-node">
						<button
							mat-icon-button
							matTreeNodeToggle
							[attr.aria-label]="'Toggle ' + node.name"
						>
							<mat-icon class="mat-icon-rtl-mirror">
								{{
									treeControl.isExpanded(node)
										? 'expand_more'
										: 'chevron_right'
								}}
							</mat-icon>
						</button>
						{{ node.name }}
					</div>
					<ul
						[class.pp-tree-invisible]="
							!treeControl.isExpanded(node)
						"
					>
						<ng-container matTreeNodeOutlet></ng-container>
					</ul>
				</li>
			</mat-nested-tree-node>
		</mat-tree>
	</mat-sidenav>
	<mat-sidenav-content class="menu-side-nav-content">
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
