import { LayoutModule } from '@angular/cdk/layout';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { httpLoaderFactory } from '@bussiness/bussiness.module';
import { PlcCommonModule } from '@components/modules/common/common.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePlayground } from './home/home.playground';
import { GeneratedCodeService } from './shared/components/generated-code/generated-code.service';
import { MaterialModule } from './shared/material.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
	declarations: [AppComponent, HomePlayground],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		PlcCommonModule,
		MaterialModule,
		SharedModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		LayoutModule,
	],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(private genCodeService: GeneratedCodeService) {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = 'assets/monaco-editor/vs/loader.js';
		script.onload = (): void => {
			(window as any).require.config({
				paths: { vs: 'assets/monaco-editor/vs' },
			});

			(window as any).require(['vs/editor/editor.main'], () => {
				this.genCodeService.emitLoadSuccess();
			});
		};
		document.getElementsByTagName('head')[0].appendChild(script);
	}
}
